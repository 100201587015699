<template>
  <div>
    <div class="PageContain">
      <div class="contain">
        <div class="left-box">
          <div class="role-list">
            <p>角色设置</p>
            <i class="el-icon-circle-plus" @click="addRole"></i>
          </div>
          <div class="table">
            <el-table
              :data="roleList"
              style="width: 100%"
              border
              :max-height="elementHeight"
              @row-click="clickRoleItem"
              :row-style="selectedstyle"
              :row-class-name="tableRowClassName"
              :stripe="true"
            >
              <el-table-column prop="roleName" width="50">
                <template slot-scope="scope">
                  <i
                    class="el-icon-setting"
                    v-if="scope.$index != roleIndex"
                  ></i>
                  <i
                    v-if="scope.$index == roleIndex"
                    class="el-icon-s-tools"
                    style="color: #5a8ef2"
                  ></i>
                </template>
              </el-table-column>
              <el-table-column prop="roleName" label="角色"> </el-table-column>
              <el-table-column prop="name" label="状态" width="80">
                <template slot-scope="scope">
                  <el-popover
                    class="popover"
                    width="150"
                    placement="top-start"
                    trigger="hover"
                  >
                    <i
                      class="el-icon-edit i-popover"
                      style="color: #8ac3f5"
                      @click="modifyRole(scope.row)"
                    >
                      修改</i
                    >
                    <i
                      class="el-icon-delete i-popover"
                      style="color: red"
                      @click="deleteRole(scope.row, scope.$index)"
                    >
                      删除</i
                    >
                    <i
                      class="el-icon-more other"
                      slot="reference"
                      v-show="roleIndex == scope.$index"
                    ></i
                  ></el-popover>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div style="margin-top: 10px">
            <div
              class="roleItem"
              v-for="(item, index) in roleList"
              :key="index"
              @click="clickRoleItem(item, index)"
              :class="[roleIndex == index ? 'itemActive' : null]"
            >
              <img
                v-show="roleIndex != index"
                src="../../assets/images/img1/four1.png"
              />
              <img
                v-show="roleIndex == index"
                src="../../assets/images/img1/four2.png"
              />
              <span>{{ item.roleName }}</span>

              <el-popover
                class="popover"
                width="150"
                placement="top-start"
                trigger="hover"
              >
                <i
                  class="el-icon-edit i-popover"
                  style="color: #8ac3f5"
                  @click="modifyRole(item)"
                >
                  修改</i
                >
                <i
                  class="el-icon-delete i-popover"
                  style="color: red"
                  @click="deleteRole(item, index)"
                >
                  删除</i
                >
                <i
                  class="el-icon-more other"
                  slot="reference"
                  v-show="roleIndex == index"
                ></i
              ></el-popover>
            </div>
          </div> -->
          </div>
        </div>

        <div class="right-div">
          <div class="table-title">
            <p>权限设置</p>
            <el-button
              type="primary"
              @click="saveData"
              style="margin-left: 20px"
              >保存设置</el-button
            >
          </div>
          <div class="table-btn">
            <p>全选</p>
            <input
              type="checkbox"
              v-for="(item, index) in headerCheckedList"
              :key="index"
              @click="handleSelectAll(item)"
              :disabled="roleIndex == null"
              v-model="item.checked"
            />
            <p></p>
          </div>
          <div class="purview">
            <el-table
              :data="tableData"
              style="width: 100%"
              border
              :height="elementHeight"
              :stripe="true"
            >
              <el-table-column prop="channelName" label="名称" width="250">
              </el-table-column>
              <el-table-column label="新增" prop="add">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.add"
                    :disabled="roleIndex == null || !scope.row.hasAdd"
                    @change="changCheckItem(scope.row, scope.row.add)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="修改" prop="edit">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.edit"
                    :disabled="roleIndex == null || !scope.row.hasEdit"
                    @change="changCheckItem(scope.row, scope.row.eide)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="删除" prop="delete">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.delete"
                    :disabled="roleIndex == null || !scope.row.hasDelete"
                    @change="changCheckItem(scope.row, scope.row.delete)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="查询" prop="search">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.search"
                    :disabled="roleIndex == null || !scope.row.hasSearch"
                    @change="changCheckItem(scope.row, scope.row.search)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="导入" prop="import">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.import"
                    :disabled="roleIndex == null || !scope.row.hasImport"
                    @change="changCheckItem(scope.row, scope.row.import)"
                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="导出" prop="export">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.export"
                    :disabled="roleIndex == null || !scope.row.hasExport"
                    @change="changCheckItem(scope.row, scope.row.export)"
                  ></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      :title="isAdd ? '新增角色' : '修改角色'"
      :showTowText="true"
      :showDialog="showDialog"
      width="20%"
      top="25vh"
      @handleReqData="handleReqData"
      @handleClose="handleClose"
    >
      <div class="_Inp">
        <span style="width: 100px">角色名称：</span>
        <el-input placeholder="请输入角色名称" v-model="roleName"> </el-input>
      </div>
      <div class="_Inp">
        <span style="width: 100px">角色编号：</span>
        <el-input placeholder="请输入角色编号" v-model="roleNo"> </el-input>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "../../components/dialog/Dialog.vue";
import { get, post, put, deleteMethod } from "../../api/http";
export default {
  components: { Dialog },
  data() {
    return {
      elementHeight: 0,
      roleList: [],
      roleIndex: null,
      tableData: [],
      initialTable: [], //最初始的数组
      roleNo: "",
      roleName: "",
      showDialog: false,
      isAdd: true,
      roleItem: {},
      // 全选
      checkAdd: false,
      checkEdit: false,
      checkDelete: false,
      checkSearch: false,
      checkImport: false,
      checkExport: false,
      headerCheckedList: [
        {
          checked: false,
          label: "新增",
        },
        {
          checked: false,
          label: "修改",
        },
        {
          checked: false,
          label: "删除",
        },
        {
          checked: false,
          label: "查询",
        },
        {
          checked: false,
          label: "导入",
        },
        {
          checked: false,
          label: "导出",
        },
      ],
    };
  },
  mounted() {
    this.getRole();
    this.getPermissions();
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 60 + 70); //70是div底部距离底部的高度
      });
    },
    // 点击table
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.roleIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#555555",
        };
      }
    },
    clickRoleItem(item) {
      this.headerCheckedList = [
        {
          checked: false,
          label: "新增",
        },
        {
          checked: false,
          label: "修改",
        },
        {
          checked: false,
          label: "删除",
        },
        {
          checked: false,
          label: "查询",
        },
        {
          checked: false,
          label: "导入",
        },
        {
          checked: false,
          label: "导出",
        },
      ],
      this.roleIndex = item.index;
      this.roleItem = item;
      this.tableData = JSON.parse(JSON.stringify(this.initialTable));
      get("/api/RoleAction?RoleNo=" + item.roleNo).then((resp) => {
        if (resp.code == 200) {
          this.tableData.map((item) => {
            resp.data.map((res) => {
              if (item.channelNo == res.channelNo) {
                item.isEdit = true;
                if (res.actionNo.includes(1)) item.add = true;
                if (res.actionNo.includes(2)) item.edit = true;
                if (res.actionNo.includes(3)) item.delete = true;
                if (res.actionNo.includes(4)) item.search = true;
                if (res.actionNo.includes(5)) item.import = true;
                if (res.actionNo.includes(6)) item.export = true;
              }
            });
          });
        }
      });
    },
    getRole() {
      get("/api/Role").then((resp) => {
        this.roleList = resp.data;
        this.roleIndex = null;
        this.tableData = JSON.parse(JSON.stringify(this.initialTable));
      });
    },
    handleClose() {
      this.showDialog = false;
    },
    addRole() {
      this.isAdd = true;
      this.showDialog = true;
      this.roleName = "";
      this.roleNo = "";
    },
    modifyRole(item) {
      this.isAdd = false;
      this.roleName = item.roleName;
      this.roleNo = item.roleNo;
      this.showDialog = true;
    },
    handleReqData() {
      if (!this.roleNo || !this.roleName) {
        this.$message({
          type: "warning",
          message: "请输入角色名称/编号",
        });
        return;
      }
      var methods = this.isAdd ? post : put;
      methods("/api/Role", {
        id: this.isAdd ? 0 : this.roleItem.id,
        roleNo: this.roleNo,
        roleName: this.roleName,
        saveUno: this.$store.state.userInfo.userNo,
        saveName: this.$store.state.userInfo.userName,
        saveDate: new Date().toISOString(),
        isAuditor: 0,
        isDelete: 0,
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: this.isAdd ? "新增成功" : "修改成功",
            });
            this.showDialog = false;
            this.getRole();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? "新增失败" : "修改失败");
        });
    },

    deleteRole(item, index) {
      this.$confirm("您确认要删除" + item.roleName + "这个角色?")
        .then(() => {
          deleteMethod("/api/Role?Id=" + item.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.roleList.splice(index, 1);
                this.roleIndex = null;
                this.tableData = JSON.parse(JSON.stringify(this.initialTable));
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    // 权限设置
    getPermissions() {
      get("/api/RoleAction/GetAllAction").then((resp) => {
        if (resp.code == 200) {
          resp.data.map((item) => {
            // 创建新的团队时 权限接口有问题 暂时替代
            if (!item.relationActionName) {
              item.relationActionName = "新增,修改,删除,查询,导入,导出";
            }
            var data = {
              channelName: item.channelName,
              channelNo: item.channelNo,
              hasAdd: item.relationActionName.includes("新增"),
              hasEdit: item.relationActionName.includes("修改"),
              hasDelete: item.relationActionName.includes("删除"),
              hasSearch: item.relationActionName.includes("查询"),
              hasImport: item.relationActionName.includes("导入"),
              hasExport: item.relationActionName.includes("导出"),
              add: false,
              edit: false,
              delete: false,
              search: false,
              import: false,
              export: false,
            };
            this.tableData.push(data);
            this.initialTable.push(data);
          });
        }
      });
    },
    changCheckItem(row, name) {
      name ? false : true;
      row.isEdit = true; //标记是否修改过
    },
    saveData() {
      if (this.roleIndex == null) {
        this.$message({
          type: "warning",
          message: "未选择关联的角色",
        });
        return;
      }
      var list = [];
      this.tableData.map((item) => {
        if (!item.isEdit) return;
        this.handlePush(item.add, item.hasAdd, item.channelNo, "1", list);
        this.handlePush(item.edit, item.hasEdit, item.channelNo, "2", list);
        this.handlePush(item.delete, item.hasDelete, item.channelNo, "3", list);
        this.handlePush(item.search, item.hasSearch, item.channelNo, "4", list);
        this.handlePush(item.import, item.hasImport, item.channelNo, "5", list);
        this.handlePush(item.export, item.hasExport, item.channelNo, "6", list);
      });
      post("/api/RoleAction?RoleNo=" + this.roleItem.roleNo, {
        data: JSON.stringify(list),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("保存失败");
        });
    },
    handlePush(condition, isHas, channelNo, num, list) {
      if (condition && isHas) {
        list.push({
          roleNo: this.roleItem.roleNo,
          channelNo: channelNo,
          actionNo: num,
        });
      }
    },
    // 点击头部全选
    handleSelectAll(column) {
      if (!column.checked) {
        column.checked = true;
      } else {
        column.checked = !column.checked;
      }
      this.tableData.map((item) => {
        switch (column.label) {
          case "新增":
            if (item.hasAdd) {
              item.isEdit = true;
              item.add = column.checked ? true : false;
            }
            break;
          case "修改":
            if (item.hasEdit) {
              item.isEdit = true;
              item.edit = column.checked ? true : false;
            }
            break;
          case "删除":
            if (item.hasDelete) {
              item.isEdit = true;
              item.delete = column.checked ? true : false;
            }
            break;
          case "查询":
            if (item.hasSearch) {
              item.isEdit = true;
              item.search = column.checked ? true : false;
            }
            break;
          case "导入":
            if (item.hasImport) {
              item.isEdit = true;
              item.import = column.checked ? true : false;
            }
            break;
          case "导出":
            if (item.hasExport) {
              item.isEdit = true;
              item.export = column.checked ? true : false;
            }
            break;
        }
      });
    },
    headerClick(column) {   //点击表格标题全选绑定 @header-click="headerClick"
      console.log(column)
      if (!column.checked) {
        column.checked = true;
      } else {
        column.checked = !column.checked;
      }
      this.tableData.map((item) => {
        switch (column.label) {
          case "新增":
            if (item.hasAdd) {
              item.isEdit = true;
              item[column.property] = column.checked ? true : false;
            }
            break;
          case "修改":
            if (item.hasEdit) {
              item.isEdit = true;
              item[column.property] = column.checked ? true : false;
            }
            break;
          case "删除":
            if (item.hasDelete) {
              item.isEdit = true;
              item[column.property] = column.checked ? true : false;
            }
            break;
          case "查询":
            if (item.hasSearch) {
              item.isEdit = true;
              item[column.property] = column.checked ? true : false;
            }
            break;
          case "导入":
            if (item.hasImport) {
              item.isEdit = true;
              item[column.property] = column.checked ? true : false;
            }
            break;
          case "导出":
            if (item.hasExport) {
              item.isEdit = true;
              item[column.property] = column.checked ? true : false;
            }
            break;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.contain {
  display: flex;
  position: absolute;
  position: absolute;
  top: 10px;
  bottom: 10px;
  width: 100%;
}
.left-box {
  width: 22%;
  position: relative;
  background-color: white;
  padding: 0 10px;
  .role-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px !important;
    background-color: white !important;
    border-bottom: 1px solid #c0c0c05c;
    p {
      color: black;
      font-weight: bold;
    }
    i {
      color: #639fe7;
      font-size: 18px;
    }
  }
  .itemActive {
    background-color: #e8f2fb;
  }
  .roleItem {
    position: relative;
    display: flex;
    align-items: center;
    padding: 7px 10px;
    img {
      width: 15px;
      height: 15px;
      padding-right: 7px;
    }
    .popover {
      position: absolute;
      right: 10px;
      width: 20px;
      height: 20px;
      .other {
        font-size: 16px !important;
        color: #7c7c7c;
      }
    }
  }
}
.i-popover {
  padding-left: 20px;
  cursor: pointer;
}
.right-div {
  width: 70%;
  margin-left: 20px;
  background-color: white;
  .table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin: 0 10px;
    border-bottom: 1px solid #c0c0c05c;
    > p {
      font-size: 13px;
      font-weight: bold;
    }
  }
}
.purview {
  width: 100%;
  overflow: auto;
}
.table {
  border: 1px solid #c0c0c05c;
  margin-top: 15px;
}
.table-btn {
  width: 100%;
  border: 1px solid #ebecee;
  display: flex;
  align-items: center;
  background-color: #e6ebf4;
  height: 30px;
  p:nth-of-type(1) {
    width: 250px;
    text-align: center;
    font-weight: bold;
  }
  input {
    flex: 1;
    text-align: center;
    border-left: 1px solid #e3e3e3;
    margin-left: -1px;
    width: 14px !important;
    height: 14px !important;
  }
  p:nth-of-type(2) {
    width: 15px;
  }
}
</style>